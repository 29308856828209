.hero-section-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 5rem;
  overflow: hidden;
}

.hero-section-container__text {
  max-width: 40%;
}

.hero-section-container__image {
  max-width: 60%;
}

.hero-section-container__image > img {
  width: 100%;
  height: auto;
  border-radius: 50%;
  filter: drop-shadow(0 0 0.75rem #d6bcfa);
  animation: 1s ease-out 0s 1 image-rotate;
  transition: all ease-in-out 0.5s;
}

.hero-section-container__image > img:hover {
  transform: rotateZ(360deg) scale(1.15);
}

@keyframes image-rotate {
  0% {
    transform: rotateZ(-360deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}

@media screen and (max-width: 700px) {
  .hero-section-container {
    flex-direction: column-reverse;
    gap: 4rem;
  }

  .hero-section-container__text {
    max-width: 100%;
  }

  .hero-section-container__image {
    max-width: 90%;
  }
}

@media screen and (max-width: 1024px) {
  .hero-section-container__interactions {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
  }
}

.hero-section-container__text__basic {
  font-size: 2em;
  line-height: 2.5rem;
  word-spacing: .3rem;
}

.hero-section-container__text__colored {
  color: #d6bcfa;
  line-height: 3rem;
  word-spacing: .3rem;
}

.hero-section-container__interactions {
  display: flex;
  justify-content: space-around;
  margin-top: 1.5rem;
}

a {
  color: inherit;
  text-decoration: inherit;
}

.btn-primary {
  outline: none;
  border: none;
  cursor: pointer;
  border: 1px solid transparent;
  color: black;
  background-color: #d6bcfa;
  padding: 0.7rem 1rem;
  border-radius: 2rem;
  font-size: 1em;
  font-weight: bold;
  transition: all ease-in-out 500ms;
}

.btn-secondary {
  color: white;
  background-color: transparent;
  border: 1px solid #d6bcfa;
  cursor: pointer;
  padding: 0.7rem 1rem;
  border-radius: 2rem;
  font-size: 1em;
  font-weight: bold;
  transition: all ease-in-out 500ms;
}

.btn-primary:hover {
  color: white;
  background-color: transparent;
  border: 1px solid #d6bcfa;
}

.btn-secondary:hover {
  color: black;
  border: 1px solid transparent;
  background-color: #d6bcfa;
}

.btn-content {
  display: flex;
  gap: 1rem;
}

p {
    margin-bottom: 1rem;
    font-weight: 400;
}