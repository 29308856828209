@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kaushan+Script&display=swap');

:root {
  --main-bg: #1A202C;
}

html,
body,
#root {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: var(--main-bg);
  color: white;
  font-family: 'Roboto', sans-serif;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}