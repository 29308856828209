nav {
  background-color: var(--main-bg);
}

ul {
  display: flex;
  flex-direction: column;
  list-style: none;
}

.branding__item__icon:hover {
  cursor: pointer;
}

.navigation__item {
  background-color: #4a5568;
  padding: 3rem;
  width: 100%;
}

.navigation__item__text {
  text-decoration: none;
  color: white;
  width: 100%;
  margin-left: auto;
}

.navigation__item:hover {
  background-color: #354769;
  cursor: pointer;

  .navigation__item__text {
    color: #d6bcfa;
  }
}

.is-hidden {
  display: none;
}

.branding {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.branding__title {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  user-select: none;
  cursor: pointer;
  font-size: 1.5rem;
  font-family: 'Kaushan Script', cursive;
  transition: color cubic-bezier(.17,.67,.83,.67) 1s;
}

.branding__title:hover {
  color: #d6bcfa;
}

.branding__item {
  margin: 1rem;
}
